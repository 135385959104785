<template>

        <div class="flex-none w-24 mr-4 text-center" ref="btnRef" v-on:mouseenter="toggleTooltip()" v-on:mouseleave="toggleTooltip()" style="transition:all .15s ease">
            <div class="h-24 w-24 p-1 mb-2 bg-white">
                <img :src="url" :alt="name" @click="addSticker(url)" class="object-contain">
            </div>
            <p class="text-xs leading-tight">{{name}}</p>
            <div ref="tooltipRef" v-bind:class="{'hidden': !tooltipShow, 'block': tooltipShow}" class="bg-gray-700 border-0 mb-48 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg">
                <div>
                    <div class="bg-gray-700 text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-gray-200 uppercase rounded-t-lg">
                        {{name}}
                    </div>
                    <div class="text-white p-3">
                        {{description}}
                    </div>
                </div>
            </div>
        </div>

</template>

<script>

    import Popper from "popper.js";
    import EventBus from "../event-bus";

    //Fixes annoying safari issue https://stackoverflow.com/questions/47549298/webpack-installing-bootstrap-missing-popper-js-map
    window.Popper = Popper;


    export default {
        name: "sticker",
        props: {
            name: '',
            url: '',
            description: ''
        },
        data() {
            return {
                tooltipShow: false
            }
        },
        methods: {
            toggleTooltip: function(){
                if(this.tooltipShow){
                    this.tooltipShow = false;
                } else {
                    this.tooltipShow = true;
                    new Popper(this.$refs.btnRef, this.$refs.tooltipRef, {
                        placement: "top"
                    });
                }
            },
            addSticker() {
                EventBus.$emit('add-sticker', this.url);
            }
        }
    }
</script>
