<template>
        <div class="flex flex-auto flex-col min-h-full">
            <share-panel :uuid="uuid" v-if="share"></share-panel>
            <div class="flex-none h-16 bg-flag-blue">
                <div class="flex justify-end w-full">
                    <img :src="logo" class="py-1 self-center logo hidden sm:block">
                    <p class="p-5 text-white mr-auto">Design a Street</p>
                    <a @click="changeBoard" class="p-5 text-white text-right cursor-pointer self-end">
                        <i class="fas fa-retweet mr-2"></i>Background
                    </a>
                    <a href="#" @click="saveBoard" class="p-5 text-white bg-green-light self-end"><i
                        class="fas fa-camera mr-2"></i>Save</a>
                </div>
            </div>

            <div class="flex-grow lg:w-2/3 w-full mx-auto my-auto relative">
                <board :backgroundImage="activeBackground"></board>
            </div>

            <div class="flex-none self-end w-full bg-gray-cool-light p-5">
                <picker :stickers="activeStickers"></picker>
            </div>
        </div>
</template>

<script>
    import EventBus from '../event-bus';

    export default {
        mounted() {
            EventBus.$on('toggleShare', () => {
                this.toggleShare();
            });
            EventBus.$on('boardSaved', (uuid) => {
                this.uuid = uuid;
            });

        },
        methods: {
            saveBoard() {
                EventBus.$emit('saveBoard');
            },
            changeBoard() {
                const totalBackgrounds = this.backgrounds.length;
                this.activeBoard = this.activeBoard == totalBackgrounds ? 1 : this.activeBoard + 1;
            },
            toggleShare() {
                this.share = !this.share;
            }
        },
        computed: {
            activeBackground: function() {
                return this.backgrounds.find(item => item.boardNumber === this.activeBoard).file;
            },
            activeStickers: function() {
                return this.stickers.filter((sticker) => {
                    return sticker.boards.includes(this.activeBoard)
                });
            }
        },
        data: function () {
            return {
                uuid: '',
                activeBoard: 1,
                share: false,
                logo: img_dir + '/chi-logo.png',
                backgrounds: [
                    {
                        boardNumber: 1,
                        file: 'bg-1.png'
                    },
                    {
                        boardNumber: 2,
                        file: 'bg-2.png'
                    },
                    {
                        boardNumber: 3,
                        file: 'bg-3.png'
                    },
                    {
                        boardNumber: 4,
                        file: 'bg-4.png'
                    }
                ],


                // =CONCATENATE("boards: [1,2,3],url: img_dir + '", B1, "',name: '",A1,"',description: '",E1,"'},")


                stickers: [

                    {boards: [1,2,3,4],url: img_dir + '/divvy-dock.png',name: 'Divvy Dock',description:
                            'Divvy is Chicago\'s bike share system. Don\'t have your own bike, just want to ride one way, or have friends in town? Grab a bike and ride!'},
                    {boards: [1,2,3,4],url: img_dir + '/divvy-van.png',name: 'Divvy van',description:
                            'Divvy is always busy moving its bikes around the city so you always have a bike when you want to ride - almost anywhere in the city! '},
                    {boards: [1,2,3,4],url: img_dir + '/usps-van.png',name: 'USPS van',description:
                            'Since rolling out in 1987, these trucks have become iconic - even if we only get junk mail. '},
                    {boards: [1,2,3,4],url: img_dir + '/vintage-car.png',name: 'Vintage car',description:
                            'This vintage baby blue car was spotted somewhere just chilling on a Chicago side street.'},
                    {boards: [1,2,3,4],url: img_dir + '/scooter-corrall.png',name: 'Scooter corrall',description:
                            'Much like the scooter parking area, a scooter corrall provides a designated area to keep scooters out of the way.'},
                    {boards: [1,2,3,4],url: img_dir + '/median.png',name: 'Landscaped median',description:
                            'Add some shade to your street with a tree-lined concrete median. Improves nearby air, reduces flooding, and is pretty: win-win-win!'},
                    {boards: [1,2,3,4],url: img_dir + '/turning-lane.png',name: 'Middle turn lane',description:
                            'Making space for a middle turning lane has been shown to make traffic flow faster, by preventing cars from blocking traffic trying to turn. '},
                    {boards: [1,2,3,4],url: img_dir + '/speedtable.png',name: 'Speed table',description:
                            'A raised bump placed in the street that forces drivers to drive slower. Most effective when there\'s lots of pedestrians nearby. '},
                    {boards: [1,2,3,4],url: img_dir + '/parking-barrier-bike-lane.png',name:
                            'Car-protected bike lane',description: 'Having parked cars between moving vehicles & bicyclists helps prevent cyclicts being hit by people opening their doors and encourages cycling!'},
                    {boards: [1,2,3,4],url: img_dir + '/bike-lane.png',name: 'Buffered 2-way bike lane',description:
                            'The gold standard of bike lanes! A favorite of cyclists everywhere that makes biking much safer & inviting for all ages. '},
                    {boards: [1,2,3,4],url: img_dir + '/rainbow-crosswalk.png',name: 'LGBT crosswalks',description:
                            'This crosswalk is a simple way to show support for LGBT people. Colorful crosswalks are also shown to encourage drivers to actually stop.'},
                    {boards: [1,2,3,4],url: img_dir + '/trans-crosswalk.png',name: 'Trans flag crosswalk',description:
                            'This crosswalk is a simple way to show support for transgender people. Colorful crosswalks are also shown to encourage drivers to actually stop.'},
                    {boards: [1,2,3,4],url: img_dir + '/blm-mural.png',name: '#BLM street mural',description:
                            'The message is clear: Black lives matter!'},
                    {boards: [1,2,3,4],url: img_dir + '/street-furniture.png',name: 'Street furniture',description:
                            'Add some pizazz to your street with miscelaneous newspaper and USPS boxes! '},
                    {boards: [1,2,3,4],url: img_dir + '/usps-box.png',name: 'USPS box',description:
                            'Mail is fundamental!'},
                    {boards: [1,2,3,4],url: img_dir + '/bioswale.png',name: 'Bioswale',description:
                            'These add some green, prevent flooding, and help make streets safer.'},
                    {boards: [1,2,3,4],url: img_dir + '/dad-stroller.png',name: 'Person with stroller',description:
                            'Almost 20% of Chicago\'s population is made up of children. How did you get around when you were a baby?'},
                    {boards: [1,2,3,4],url: img_dir + '/woman-walker.png',name: 'Person with walker',description:
                            'In Chicago, about 6% of residents report an ambulatory disability, or a physical disability that makes it difficult to walk around unassisted. Streets must be designed for all levels of ability!'},
                    {boards: [1,2,3,4],url: img_dir + '/scooter-rider.png',name: 'Person on scooter',description:
                            'Scooters are a great addition to the ways people get around, but remember to share the road safely with other motor vehicles!'},
                    {boards: [1,2,3,4],url: img_dir + '/runner.png',name: 'Runner',description:
                            'Runners often run on the road instead of the sidewalk. Ensure their safety and make sure they are running against traffic!'},
                    {boards: [1,2,3,4],url: img_dir + '/protest.png',name: 'Protesters',description:
                            'Protesters exercise their right to assembly in public areas such as streets and parks.'},
                    {boards: [1,2,3,4],url: img_dir + '/pedestrian-island.png',name: 'Median island',description:
                            'These help shorten distances to cross and help protect pedestrians by making them more visible & forcing cars not to cut across the crosswalk.'},
                    {boards: [1,2,3,4],url: img_dir + '/planter-1.png',name: 'Planter',description:
                            'Add some seasonal décor to your street!'},
                    {boards: [1,2,3,4],url: img_dir + '/tree.png',name: 'Tree',description:
                            'Breathe easier with a street tree!'},
                    {boards: [1,2,3,4],url: img_dir + '/bus.png',name: 'CTA bus',description:
                            'The CTA operates over 1,500 buses in Chicago!'},
                    {boards: [1,2,3,4],url: img_dir + '/crosswalk.png',name: 'Crosswalks',description:
                            'These striped lines denote where pedestrians should cross.'},
                    {boards: [1,2,3,4],url: img_dir + '/speed-limit.png',name: 'Speed sign',description:
                            'These signs show you how much you\'re speeding and not much else.'},
                    {boards: [1,2,3,4],url: img_dir + '/bus-stop.png',name: 'Bus Stop',description:
                            'This is where people board or get off the bus. '},
                    {boards: [1,2,3,4],url: img_dir + '/planter.png',name: 'Pilot Intersection bumpout',description:
                            'Some paint & planters is all you need to make intersections safer! These help protect pedestrians by avoiding conflicts with cars.'},
                    {boards: [1,2,3,4],url: img_dir + '/bike-box.png',name: 'Bike box',description:
                            'A designated space for cyclists to wait at a stoplight. Helps make cyclists more visible & gives them a head start when the light turns green.'},
                    {boards: [1,2,3,4],url: img_dir + '/runner-woman.png',name: 'Woman pedestrian',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/hijabis.png',name: 'Two women',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/business-man.png',name: 'Man',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/business-woman.png',name: 'Professional woman',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/man-walking.png',name: 'Man with ear buds',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/woman-flowers.png',name: 'Woman with flowers',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/man-phone.png',name: 'Man on phone',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/pedestrian-b.png',name: 'Woman with bag',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/pushcart.png',name: 'Woman with pushcart',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/divvy-drag.png',name: 'Drag queen',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/skateboarder.png',name: 'Man with skateboard',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/pedestrian.png',name: 'Man',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/woman-child.png',name: 'Mom with child',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/woman-carrying-child.png',name: 'Mom holding child',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/motorcyclist.png',name: 'Person on motorcycle',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/divvy.png',name: 'Man with e-Divvy',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/latina-cyclist.png',name: 'Woman with bike',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/woman-cyclist.png',name: 'Woman with bike',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/cyclist-back.png',name: 'Turning cyclist gesturing',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/cyclist-1.png',name: 'Man on bike',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/cyclist.png',name: 'Man on bike',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/cyclist-forward.png',name: 'Forward-facing person with bike',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/bike-rack.png',name: 'Bike rack',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/blue-car.png',name: 'Blue car',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/blue-square.png',name: 'Blue square',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/book-box.png',name: 'Book box',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/corner.png',name: 'Corner',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/green-square.png',name: 'Green square',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/mixed-use.png',name: 'Mixed use',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/orange-square.png',name: 'Orange square',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/planter-2.png',name: 'Planter',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/planter-3.png',name: 'Planter',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/purple-square.png',name: 'Purple square',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/red-car.png',name: 'Red car',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/restaurant.png',name: 'Restaurant ',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/stoplight.png',name: 'Stoplight',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/streetlamp.png',name: 'Light pole with planter (left)',description: 'These add character to your street - now with more sustainable white LEDs and planters too!'},
                    {boards: [1,2,3,4],url: img_dir + '/streetlamp2.png',name: 'Light pole with planter (right)',description: 'These add character to your street - now with more sustainable white LEDs and planters too!'},
                    {boards: [1,2,3,4],url: img_dir + '/suv.png',name: 'SUV',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/theater.png',name: 'Theater',description: ''},
                    {boards: [1,2,3,4],url: img_dir + '/tree-2.png',name: 'Tree',description: ''},
                ],
            }
        }
    }
</script>
