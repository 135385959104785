<template>

    <div class="flex flex-row overflow-x-scroll max-h-full">
        <sticker v-for="(sticker, index) in stickers" :name="sticker.name" :key="index" :description="sticker.description" :url="sticker.url"/>
    </div>

</template>

<script>

    export default {
        props: {
            stickers: Array
        },
        data: function() {
            return {

            }
        }
    }


</script>
