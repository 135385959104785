<template>

    <div class="absolute top-0 bottom-0 right-0 left-0" ref="boardWrap">
        <canvas ref="board" v-bind:style="{ marginTop: marginTop + 'px'}"></canvas>
    </div>

</template>

<script>
    import { fabric } from 'fabric';
    import EventBus from '../event-bus';

    export default {
        props: {
            backgroundImage: String,
        },
        watch: {
            backgroundImage: function () {
                this.updateBackground();
            }
        },
        data: function () {
            return {
                board: '',
                deleteImg: '',
                marginTop: 0
            }
        },
        mounted() {
            this.$nextTick(function() {
                window.addEventListener('load', ()=>{
                    this.board = new fabric.Canvas(this.$refs.board, {
                        width: this.boardContainerSize(),
                        height: this.boardContainerSize()
                    });

                    this.updateBackground();
                });
                window.addEventListener('resize', () =>  {
                    this.resizeAll();
                });
            });

            EventBus.$on('add-sticker', (url) => {
                this.addSticker(url);
            });
            EventBus.$on('saveBoard', () => {
                this.saveBoard();
            });
        },
        methods: {
            updateBackground() {
                const img = new fabric.Image();

                img.setSrc(img_dir + '/' + this.backgroundImage, ()=> {
                    img.set({
                        top: 0,
                        left: 0,
                        scaleX: this.boardContainerSize() / img.width,
                        scaleY: this.boardContainerSize() / img.height,
                        originX: 'left',
                        originY: 'top'
                    });
                    this.verticalAlignBoard();
                    this.board.setBackgroundImage(img);
                    this.board.requestRenderAll();
                });
            },
            saveBoard() {
                if (!window.localStorage) {
                    alert("This function is not supported by your browser.");
                    return;
                }

                const link = document.createElement('a');
                link.download = 'design-a-street.png';

                //Resize canvas to 1028x1028 for export
                this.resizeBoard(1080);
                this.resizeBackground(1080);

                const dataUrl = this.board.toDataURL({
                    format: 'png',
                    width: 1080,
                    height: 1080
                });

                let imageFormData = new FormData();
                imageFormData.set('image', dataUrl);

                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                };

                return axios.post(window._asset + 'api/upload', imageFormData, config)
                    .then(data => {
                        //Reset the board visibility
                        this.resizeAll();

                        //Download the image
                        link.href = dataUrl;
                        document.body.appendChild(link);
                        setTimeout(function() {
                            link.click();
                            document.body.removeChild(link);
                        }, 500);

                        //Open open the share window
                        EventBus.$emit('boardSaved', data.data.uuid);
                        EventBus.$emit('toggleShare');
                    })
                    .catch(err => {
                        //Reset the board visibility
                        this.resizeAll();

                        if (err.response) {
                            console.log(err);
                            alert('The server response contained an error. Please contact support.');
                        } else if (err.request) {
                            console.log(err);
                            alert('The server request contained an error. Please contact support.')
                        } else {
                            console.log(err);
                            alert('There was a general error. Please contact support.')
                        }
                    })
            },
            boardContainerSize(){
                const containerWidth = this.$refs.boardWrap.clientWidth;
                const containerHeight = this.$refs.boardWrap.clientHeight;
                let containerMin = containerHeight < containerWidth ? containerHeight : containerWidth;
                return containerMin;
            },
            resizeAll() {
                this.resizeBoard();
                this.resizeBackground();
                this.verticalAlignBoard();
            },
            verticalAlignBoard(){
                this.marginTop = (this.$refs.boardWrap.clientHeight - this.boardContainerSize()) / 2;
            },
            resizeBackground(size = this.boardContainerSize()){
                this.board.backgroundImage.scaleToWidth(size);
                this.board.backgroundImage.scaleToHeight(size);
            },
            setInitialBoardSize(board){
                const size = this.boardContainerSize();
                board.setDimensions({width: size, height: size});
            },
            resizeBoard(size = this.boardContainerSize()){
                const scale = size / this.board.getWidth();
                this.board.setDimensions({width: size, height: size});
                const zoom  = this.board.getZoom() * scale;
                this.board.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
            },
            addSticker(url){
                fabric.Object.prototype.transparentCorners = false;
                fabric.Object.prototype.cornerColor = 'blue';
                fabric.Object.prototype.cornerStyle = 'square';
                fabric.Object.prototype.controls.deleteControl = new fabric.Control({
                    x: .5,
                    y: -.5,
                    cursorStyle: 'pointer',
                    mouseUpHandler: this.deleteObject,
                    render: this.renderIcon,
                    cornerSize: 100
                });

                fabric.Image.fromURL(url, (img) => {
                    const size = Math.round((this.boardContainerSize() / 5)/this.board.getZoom());

                    img.scaleToWidth(size, false);
                    img.scaleToHeight(size, false);

                    this.board.add(img);
                });
            },
            renderIcon(ctx, left, top, styleOverride, fabricObject) {
                const deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";

                const deleteImg = document.createElement('img');
                deleteImg.src = deleteIcon;
                const size = 24;
                ctx.save();
                ctx.translate(left, top);
                ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                ctx.drawImage(deleteImg, -size/2, -size/2, size, size);
                ctx.restore();
            },
            deleteObject(eventData, target) {
                const canvas = target.canvas;
                canvas.remove(target);
                canvas.requestRenderAll();
            }
        },
        beforeDestroy() {
            window.removeEventListener('resize', () => this.resizeCanvas(board));
        },
    }
</script>
