<template>
    <div class="absolute top-0 bottom-0 right-0 left-0 bg-white z-50 p-5">
        <close-button></close-button>

        <div class="flex flex-col text-center mt-20">
            <h1 class="text-2xl mb-10">Share Your Design!</h1>
            <ShareNetwork
                network="facebook"
                :url="url"
                :title="title"
                :description="description"
            >
                <button class="w-64 mb-5 text-blue-700 bg-transparent border border-solid border-blue-700 uppercase bold text-sm px-6 py-3  rounded outline-none mr-1 mb-1" type="button">
                    <i class="fab fa-facebook-f mr-1"></i> Facebook
                </button>
            </ShareNetwork>
            <ShareNetwork
                network="twitter"
                :url="url"
                :title="title"
                :description="description"
            >
                <button class="w-64 mb-5 text-blue-400 bg-transparent border border-solid border-blue-400 uppercase bold text-sm px-6 py-3  rounded outline-none mr-1 mb-1" type="button">
                    <i class="fab fa-twitter mr-1"></i> Twitter
                </button>
            </ShareNetwork>
            <ShareNetwork
                network="pinterest"
                :url="url"
                :title="title"
                :description="description"
            >
                <button class="w-64 mb-5 text-red-700 bg-transparent border border-solid border-red-700 uppercase bold text-sm px-6 py-3  rounded outline-none mr-1 mb-1" type="button">
                    <i class="fab fa-pinterest mr-1"></i> Pinterest
                </button>
            </ShareNetwork>
            <ShareNetwork
                network="email"
                :url="url"
                :title="title"
                :description="description"
            >
                <button class="w-64 mb-5 text-gray-700 bg-transparent border border-solid border-gray-700 uppercase bold text-sm px-6 py-3  rounded outline-none mr-1 mb-1" type="button">
                    <i class="fab fa-email mr-1"></i> Email
                </button>
            </ShareNetwork>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            uuid: String,
        },
        data () {
            return {
                title: 'My Street Design',
                description: 'Check out the street design I made at Design A Street - www.designastreet.com'
            }
        },
        computed: {
            url: function() {
                console.log('this.uuid' + this.uuid);
                return window._asset + 'image/' + this.uuid;
            },
        },
    }
</script>


